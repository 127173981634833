import React from 'react';

import Section from '../section';

const SectionInterests = ({ interests }) => {
  return (
    <Section title="Interests">
      <div className="mb-6">
        <p>{interests}</p>
      </div>
    </Section>
  );
};

export default SectionInterests;
